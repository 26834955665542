import * as actions from './constants'
import priceToString from '../../utils/price-to-string'
import parseDateString from '../../utils/parse-date-string'

const initialState = {
  discounts: [],
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  currentDiscountData: null,
  availabilities: null,
  itemsPerPage: 20
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_DISCOUNTS_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_DISCOUNT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.SET_DISCOUNT_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.DISCOUNTS_FETCHED:
      return {
        ...state,
        discounts: action.payload.list,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.DELETE_DISCOUNT:
      return {
        ...state,
        discounts: state.discounts.filter(({ id }) => id !== action.payload)
      }
    case actions.DISCOUNT_DATA_CLEAR:
      return {
        ...state,
        currentDiscountData: null,
        availabilities: null
      }
    case actions.DISCOUNT_UPDATED:
    case actions.DISCOUNT_FETCHED: {
      const currentDiscountData = action.payload
      currentDiscountData.cash_value = priceToString(currentDiscountData.cash_value)
      currentDiscountData.date_start = parseDateString(currentDiscountData.date_start)
      currentDiscountData.date_end = parseDateString(currentDiscountData.date_end)
      return {
        ...state,
        currentDiscountData,
        availabilities: currentDiscountData.availabilities
      }
    }
    case actions.DISCOUNT_IMAGE_ADDED:
      return {
        ...state,
        currentDiscountData: {
          ...state.currentDiscountData,
          image: action.payload
        }
      }
    case actions.DISCOUNT_IMAGE_REMOVED:
      return {
        ...state,
        currentDiscountData: {
          ...state.currentDiscountData,
          image: null
        }
      }
    case actions.DISCOUNT_STORES_AVAILABILITY_FETCHED:
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          available_stores: action.payload
        }
      }
    case actions.DISCOUNT_NETWORKS_AVAILABILITY_FETCHED:
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          available_store_networks: action.payload
        }
      }
    default:
      return state
  }
}
