import * as actions from './constants'

const initialState = {
  networks: [],
  networkItemFormData: null,
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  itemsPerPage: 20
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_STORE_NETWORKS_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_STORE_NETWORKS_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.SET_STORE_NETWORKS_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.STORE_NETWORKS_FETCHED:
      return {
        ...state,
        networks: action.payload.list,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.DELETE_STORE_NETWORK:
      return {
        ...state,
        networks: state.networks.filter(({ id }) => id !== action.payload)
      }
    case actions.NEW_NETWORK_CREATED:
      return {
        ...state,
        networks: [action.payload, ...state.networks]
      }
    case actions.NETWORK_FETCHED:
      return {
        ...state,
        networkItemFormData: action.payload
      }
    case actions.NETWORK_FORM_DATA_CLEAR:
      return {
        ...state,
        networkItemFormData: null
      }
    case actions.NETWORK_UPDATED:
      return {
        ...state,
        networkItemFormData: action.payload
      }
    default:
      return state
  }
}
