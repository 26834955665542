import * as actions from './constants'
import priceToString from '../../utils/price-to-string'

const initialState = {
  orders: [],
  pendingOrders: [],
  products: [],
  single_products: [],
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  itemsPerPage: 20,
  orderFormData: {
    driver_instructions: null,
    private_notes: null,
    driver_tips: 0
  }
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_ORDERS_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_ORDERS_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.SET_ORDERS_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.ORDERS_FETCHED:
      return {
        ...state,
        orders: action.payload.list,
        customers: action.payload.metadata && action.payload.metadata.customers,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.ORDER_UPDATED:
    case actions.ORDER_FETCHED: {
      const currentOrderData = action.payload
      currentOrderData.driver_tips = priceToString(currentOrderData.driver_tips)
      return {
        ...state,
        orderFormData: currentOrderData,
        products: currentOrderData.products,
        single_products: currentOrderData.single_products,
        orders: state.orders.map(order => {
          return order.id === currentOrderData.id ? currentOrderData : order
        })
      }
    }
    case actions.DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter(({ id }) => id !== action.payload)
      }
    case actions.CLEAR_ORDER_DATA:
      return {
        ...state,
        orderFormData: {},
        products: [],
        single_products: []
      }
    case actions.CHANGE_PRODUCT_COUNT: {
      const { productId, count } = action.payload
      const { products = [], single_products = [] } = state
      function counter (items) {
        return items.map(entry => {
          return (entry.product ? entry.product.id : entry.id) === productId
            ? { ...entry, count }
            : entry
        })
          .filter(({ count }) => count)
      }
      return {
        ...state,
        products: counter(products),
        single_products: counter(single_products)
      }
    }

    case actions.ORDER_ADD_PRODUCT: {
      const { products = [] } = state
      const { product } = action.payload
      return {
        ...state,
        products: [...products, { count: 1, product }]
      }
    }

    case actions.CREATE_SINGLE_PRODUCT: {
      const singleProducts = state.single_products || []
      const singleProduct = action.payload
      return {
        ...state,
        single_products: [...singleProducts, singleProduct]
      }
    }
    case actions.PENDING_ORDERS_FETCHED: {
      return {
        ...state,
        pendingOrders: action.payload.list
      }
    }
    default:
      return state
  }
}
