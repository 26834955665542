import * as actions from './constants'

export function addConfirmation ({
  id,
  title,
  description,
  onClick,
  buttons = [],
  isShown = true
}) {
  return dispatch => dispatch({
    type: actions.ADD_CONFIRMATION,
    payload: { id, title, description, onClick, buttons, isShown }
  })
}

export function removeConfirmation (confirmationId) {
  return dispatch => dispatch({
    type: actions.REMOVE_CONFIRMATION,
    payload: confirmationId
  })
}
