export const CATEGORIES_FETCHED = 'CATEGORIES_FETCHED'
export const SET_CATEGORIES_PROGRESS = 'SET_CATEGORIES_PROGRESS'
export const SET_CATEGORIES_PAGE = 'SET_CATEGORIES_PAGE'
export const SET_CATEGORIES_ITEMS_PER_PAGE = 'SET_CATEGORIES_ITEMS_PER_PAGE'
export const SET_CATEGORY_DEPARTMENT_ID = 'SET_CATEGORY_DEPARTMENT_ID'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const CLEAR_CATEGORIES_ITEM_DATA = 'CLEAR_CATEGORIES_ITEM_DATA'
export const CATEGORIES_ITEM_FETCHED = 'CATEGORIES_ITEM_FETCHED'
export const CATEGORIES_ITEM_EDITED = 'CATEGORIES_ITEM_EDITED'
export const CATEGORIES_ITEM_ADDED = 'CATEGORIES_ITEM_ADDED'
