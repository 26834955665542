import * as actions from './constants'

const initialState = {
  departments: [],
  departmentsItemFormData: null,
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  itemsPerPage: 20
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_DEPARTMENTS_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_DEPARTMENTS_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.SET_DEPARTMENTS_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.DEPARTMENTS_FETCHED:
      return {
        ...state,
        departments: action.payload.list,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.DELETE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.filter(({ id }) => id !== action.payload)
      }
    case actions.CLEAR_DEPARTMENTS_ITEM_DATA:
      return {
        ...state,
        departmentsItemFormData: null
      }
    case actions.DEPARTMENTS_ITEM_FETCHED:
      return {
        ...state,
        departmentsItemFormData: action.payload
      }
    case actions.DEPARTMENTS_ITEM_IMAGE_ADDED:
      return {
        ...state,
        departmentsItemFormData: {
          ...state.departmentsItemFormData,
          [action.payload.targetProperty]: action.payload.image
        }
      }
    case actions.DEPARTMENTS_ITEM_IMAGE_REMOVED:
      return {
        ...state,
        departmentsItemFormData: {
          ...state.departmentsItemFormData,
          [action.payload]: null
        }
      }
    default:
      return state
  }
}
