import * as actions from './constants'

const initialState = {
  tips: [],
  tipsItemFormData: null,
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  itemsPerPage: 20
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_TIPS_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_TIPS_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.SET_TIPS_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.TIPS_FETCHED:
      return {
        ...state,
        tips: action.payload.list,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.DELETE_TIP:
      return {
        ...state,
        tips: state.tips.filter(({ id }) => id !== action.payload)
      }
    case actions.CLEAR_TIPS_ITEM_DATA:
      return {
        ...state,
        tipsItemFormData: null
      }
    case actions.TIPS_ITEM_FETCHED:
    case actions.TIPS_ITEM_EDITED:
    case actions.TIPS_ITEM_ADDED:
      return {
        ...state,
        tipsItemFormData: action.payload
      }
    default:
      return state
  }
}
