export const COUPONS_FETCHED = 'COUPONS_FETCHED'
export const COUPON_FETCHED = 'COUPON_FETCHED'
export const SET_COUPONS_PROGRESS = 'SET_COUPONS_PROGRESS'
export const SET_COUPONS_PAGE = 'SET_COUPONS_PAGE'
export const SET_COUPONS_ITEMS_PER_PAGE = 'SET_COUPONS_ITEMS_PER_PAGE'
export const DELETE_COUPON = 'DELETE_COUPON'
export const CLEAR_COUPON_DATA = 'CLEAR_COUPON_DATA'
export const COUPON_ADDED = 'COUPON_ADDED'
export const COUPON_EDITED = 'COUPON_EDITED'
export const COUPON_STORES_AVAILABILITY_FETCHED = 'COUPON_STORES_AVAILABILITY_FETCHED'
export const COUPON_NETWORKS_AVAILABILITY_FETCHED = 'COUPON_NETWORKS_AVAILABILITY_FETCHED'
