import { SHOW_MESSAGE, CLOSE_MESSAGE } from './constants'

const initialState = {
  message: ''
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        message: action.message
      }
    case CLOSE_MESSAGE:
      return {
        ...state,
        message: action.message
      }
    default:
      return state
  }
}
