export const CATALOG_PRODUCTS_PAGE_FETCHED = 'CATALOG_PRODUCTS_PAGE_FETCHED'
export const CATALOG_PRODUCTS_PRODUCT_FETCHED = 'CATALOG_PRODUCTS_PRODUCT_FETCHED'
export const CATALOG_PRODUCTS_PRODUCT_CLEAR = 'CATALOG_PRODUCTS_PRODUCT_CLEAR'
export const CATALOG_PRODUCTS_CLEAR = 'CATALOG_PRODUCTS_CLEAR'
export const CATALOG_PRODUCTS_PRODUCT_SAVED = 'CATALOG_PRODUCTS_PRODUCT_SAVED'
export const CATALOG_PRODUCTS_PRODUCT_CREATED = 'CATALOG_PRODUCTS_PRODUCT_CREATED'
export const CATALOG_PRODUCTS_SET_PAGE = 'CATALOG_PRODUCTS_SET_PAGE'
export const CATALOG_PRODUCTS_SET_ITEMS_PER_PAGE = 'CATALOG_PRODUCTS_SET_ITEMS_PER_PAGE'
export const CATALOG_PRODUCTS_LOADING = 'CATALOG_PRODUCTS_LOADING'
export const CATALOG_PRODUCTS_LOADING_STOP = 'CATALOG_PRODUCTS_LOADING_STOP'
export const CATALOG_PRODUCTS_LOCATIONS_FETCHED = 'CATALOG_PRODUCTS_LOCATIONS_FETCHED'
export const CATALOG_LOCATION_SET = 'CATALOG_LOCATION_SET'
export const CATALOG_PRODUCTS_ORDERINGS_FETCHED = 'CATALOG_PRODUCTS_ORDERINGS_FETCHED'
export const SET_ORDERINGS_PAGE = 'SET_ORDERINGS_PAGE'
export const SET_ORDERINGS_ITEMS_PER_PAGE = 'SET_ORDERINGS_ITEMS_PER_PAGE'
export const CATALOG_PRODUCTS_PRODUCT_CATEGORY = 'CATALOG_PRODUCTS_PRODUCT_CATEGORY'
export const CATALOG_PRODUCTS_SINGLE_PRODUCT_FETCHED = 'CATALOG_PRODUCTS_SINGLE_PRODUCT_FETCHED'
export const CATALOG_PRODUCTS_ARRIVALS_PAGE_FETCHED = 'CATALOG_PRODUCTS_ARRIVALS_PAGE_FETCHED'
export const CATALOG_PRODUCTS_ADD_NEW_ARRIVALS = 'CATALOG_PRODUCTS_ADD_NEW_ARRIVALS'
export const CATALOG_PRODUCT_STORE_PRICE = 'CATALOG_PRODUCT_STORE_PRICE'
export const CATALOG_PRODUCTS_PRICES_FETCHED = 'CATALOG_PRODUCTS_PRICES_FETCHED'
export const CATALOG_PRODUCTS_NETWORK_PRICES_FETCHED = 'CATALOG_PRODUCTS_NETWORK_PRICES_FETCHED'
export const CATALOG_PRODUCT_NETWORK_PRICE = 'CATALOG_PRODUCT_NETWORK_PRICE'
export const CATALOG_PRODUCT_ADD_PRODUCT = 'CATALOG_PRODUCT_ADD_PRODUCT'
export const CATALOG_CHANGE_PRODUCT_COUNT = 'CATALOG_CHANGE_PRODUCT_COUNT'
export const CATALOG_CLEAR_CONTAINED_PRODUCTS = 'CATALOG_CLEAR_CONTAINED_PRODUCTS'
