import * as accountsActions from './constants'
import priceToString from '../../utils/price-to-string'

const initialState = {
  userFormData: {},
  users: {
    loading: false,
    list: []
  },
  customers: {
    loading: false,
    list: [],
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 20
  },
  addressData: null,
  addresses: [],
  bonuses: null,
  customerCart: []
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case accountsActions.ACCOUNTS_USERS_FETCHED: {
      return {
        ...state,
        users: {
          loading: false,
          list: action.payload
        }
      }
    }
    case accountsActions.ACCOUNTS_USERS_REMOVE: {
      const newState = { ...state }
      newState.users = state.users.filter(
        user => user.id !== action.payload.id
      )
      return newState
    }
    case accountsActions.ACCOUNTS_SET_USER_FORM_DATA: {
      return {
        ...state,
        userFormData: action.payload,
        addresses: action.payload.addresses || []
      }
    }
    case accountsActions.ACCOUNTS_CUSTOMERS_SET_PAGE:
      return {
        ...state,
        customers: {
          ...state.customers,
          currentPage: action.payload
        }
      }
    case accountsActions.ACCOUNTS_CUSTOMERS_SET_ITEMS_PER_PAGE:
      return {
        ...state,
        customers: {
          ...state.customers,
          itemsPerPage: action.payload
        }
      }
    case accountsActions.ACCOUNTS_CUSTOMER_PASSWORD_CHANGE:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: false
        }
      }
    case accountsActions.ACCOUNTS_CUSTOMERS_LOADING:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: true
        }
      }
    case accountsActions.ACCOUNTS_CUSTOMERS_LOADING_STOP:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: false
        }
      }
    case accountsActions.ACCOUNTS_CUSTOMERS_FETCHED:
      return {
        ...state,
        customers: {
          loading: false,
          list: action.payload.list,
          totalItems: action.payload.paging_header.total_items,
          totalPages: action.payload.paging_header.total_pages,
          currentPage: state.customers.currentPage
        }
      }
    case accountsActions.ACCOUNTS_CUSTOMERS_CHANGE_STATUS: {
      const { statusId, customerId } = action.payload
      const patchedList = state.customers.list.map(item =>
        item.id === customerId ? { ...item, status: statusId } : item
      )
      return {
        ...state,
        customers: {
          ...state.customers,
          list: patchedList,
          loading: false
        }
      }
    }
    case accountsActions.ACCOUNTS_CUSTOMERS_CLEAR_DATA:
      return {
        ...state,
        userFormData: null,
        addresses: []
      }
    case accountsActions.ACCOUNTS_CUSTOMERS_ADD_ADDRESS: {
      const { data: newAddress } = action.payload
      const { userFormData = {} } = state || {}
      const { addresses = [] } = userFormData || {}
      return {
        ...state,
        userFormData: {
          ...userFormData
        },
        addresses: [...addresses, newAddress]
      }
    }
    case accountsActions.ACCOUNTS_CUSTOMERS_REMOVE_ADDRESS: {
      const { addressId } = action.payload
      return {
        ...state,
        userFormData: {
          ...state.userFormData,
          addresses: [
            ...state.addresses.filter(({ id }) => id !== Number(addressId))
          ]
        },
        addresses: [
          ...state.addresses.filter(({ id }) => id !== Number(addressId))
        ]
      }
    }
    case accountsActions.ACCOUNTS_CUSTOMERS_INIT_ADDRESS_DATA: {
      const addressData = action.payload
      return {
        ...state,
        addressData
      }
    }
    case accountsActions.ACCOUNTS_CUSTOMERS_EDIT_ADDRESS: {
      const updatedAddress = action.payload
      const { addresses = [] } = state.userFormData
      return {
        ...state,
        userFormData: {
          ...state.userFormData
        },
        addresses: [
          ...addresses.map(address => {
            return address.id === updatedAddress.id ? updatedAddress : address
          })
        ]
      }
    }
    case accountsActions.ACCOUNTS_CUSTOMER_BONUSES_FETCHED: {
      const referrals = action.payload.referrals.map(referral => {
        const referralRerfreshed = { ...referral, bonuses: priceToString(referral.bonuses) }
        return referralRerfreshed
      })
      return {
        ...state,
        bonuses: {
          total: priceToString(action.payload.total),
          available: priceToString(action.payload.available),
          referrals: referrals
        }
      }
    }
    case accountsActions.ACCOUNTS_USERS_LOADING:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true
        }
      }
    case accountsActions.ACCOUNTS_USERS_LOADING_STOP:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false
        }
      }
    case accountsActions.ACCOUNTS_CUSTOMER_CART_FETCHED: {
      return {
        ...state,
        customerCart: action.payload
      }
    }
    default:
      return state
  }
}
