import axios from 'axios'
import HttpErrorService from './HttpErrorService'

const CINCH_API = process.env.REACT_APP_API_URL

class ApiService {
  apiUrl = CINCH_API;
  authHeader = null;
  httpErrorService = HttpErrorService;
  
  setAuthHeader (authHeader) {
    this.authHeader = `Bearer ${authHeader}`
  }

  async request ({ method, url, data, opts = {} }) {

    if (this.authHeader) {
      if (!opts.headers) {
        opts.headers = {}
      }

      opts.headers['Authorization'] = this.authHeader
    }

    try {
      const response = await axios.request({
        method: method,
        url: this.apiUrl + url,
        data: data,
        headers: opts.headers
      })
      return response.data
    } catch (e) {
      this.httpErrorService.handleResponse(e)
      throw e
    }
  }

  get (url, opts) {
    return this.request({ method: 'get', url, opts })
  }

  post (url, data, opts) {
    return this.request({ method: 'post', url, data, opts })
  }

  put (url, data, opts) {
    return this.request({ method: 'put', url, data, opts })
  }

  delete (url, data, opts) {
    return this.request({ method: 'delete', url, data, opts })
  }
}
export default new ApiService()
