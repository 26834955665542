import { usersService } from '../../services/UsersService'
import { SET_AUTH_PROGRESS, SET_AUTH_ERROR, LOGIN, LOGOUT } from './constants'

export function doLogin (credentials) {
  return async dispatch => {
    try {
      dispatch({
        type: SET_AUTH_ERROR,
        payload: null
      })
      dispatch({
        type: SET_AUTH_PROGRESS,
        payload: true
      })
      const apiToken = await usersService.login(credentials)
      dispatch({ type: LOGIN, payload: apiToken })
    } catch (error) {
      let errorMessage = ''
      try {
        errorMessage = error.response.data.errors.map(e => e.message).join(' ')
      } catch (error) {
        errorMessage = 'Authentication error'
      }
      dispatch({
        type: SET_AUTH_ERROR,
        payload: errorMessage
      })
    } finally {
      dispatch({
        type: SET_AUTH_PROGRESS,
        payload: false
      })
    }
  }
}

export const doLogout = () => dispatch => dispatch({ type: LOGOUT })
