export const ORDERS_FETCHED = 'ORDERS_FETCHED'
export const ORDER_FETCHED = 'ORDER_FETCHED'
export const SET_ORDERS_PROGRESS = 'SET_ORDERS_PROGRESS'
export const SET_ORDERS_PAGE = 'SET_ORDERS_PAGE'
export const SET_ORDERS_ITEMS_PER_PAGE = 'SET_ORDERS_ITEMS_PER_PAGE'
export const DELETE_ORDER = 'DELETE_ORDER'
export const CLEAR_ORDER_DATA = 'CLEAR_ORDER_DATA'
export const ORDER_CREATED = 'ORDER_CREATED'
export const ORDER_UPDATED = 'ORDER_UPDATED'
export const CHANGE_PRODUCT_COUNT = 'CHANGE_PRODUCT_COUNT'
export const PENDING_ORDERS_FETCHED = 'PENDING_ORDERS_FETCHED'
export const CREATE_SINGLE_PRODUCT = 'CREATE_SINGLE_PRODUCT'
export const ORDER_ADD_PRODUCT = 'ORDER_ADD_PRODUCT'
