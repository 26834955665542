import * as actions from './constants'

const initialState = {
  news: [],
  newsItemFormData: null,
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  itemsPerPage: 20
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_NEWS_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_NEWS_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.SET_NEWS_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.NEWS_FETCHED:
      return {
        ...state,
        news: action.payload.list,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.DELETE_NEWS_ITEM:
      return {
        ...state,
        news: state.news.filter(({ id }) => id !== action.payload)
      }
    case actions.CLEAR_NEWS_ITEM_DATA:
      return {
        ...state,
        newsItemFormData: null
      }
    case actions.NEWS_ITEM_FETCHED:
    case actions.NEWS_ITEM_ADDED:
    case actions.NEWS_ITEM_EDITED:
      return {
        ...state,
        newsItemFormData: action.payload
      }
    default:
      return state
  }
}
