import { SET_ERROR, DELETE_ERROR } from './constants'

export function showError (e) {
  if (!e.response) {
    return async dispatch => {
      dispatch({
        type: SET_ERROR,
        payload: e.message
      })
    }
  }

  if (e.response.status >= 400) {
    let errorMessage = ''

    if (e.response.data && e.response.data.errors && e.response.data.errors.length > 0) {
      errorMessage = e.response.data.errors.map(e => e.message)
    } else {
      errorMessage = e.message
    }

    return async dispatch => {
      dispatch({
        type: SET_ERROR,
        payload: errorMessage
      })
    }
  } else {
    const errorMessage = e.message
    return async dispatch => {
      dispatch({
        type: SET_ERROR,
        payload: errorMessage
      })
    }
  }
}

export function deleteError () {
  return dispatch => dispatch({
    type: DELETE_ERROR
  })
}
