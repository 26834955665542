import * as actions from './constants'

const initialState = {
  departments: null,
  categories: null
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.NAV_DEPARTMENTS_FETCHED:
      return {
        ...state,
        departments: action.payload
      }
    default:
      return state
  }
}
