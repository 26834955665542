import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import accounts from './accounts/reducer'
import dashboardControls from './default/reducer'
import products from './products/reducer'
import categories from './categories/reducer'
import departments from './departments/reducer'
import orders from './orders/reducer'
import networks from './store-networks/reducer'
import stores from './stores/reducer'
import discounts from './discounts/reducer'
import coupons from './coupons/reducer'
import news from './news/reducer'
import tips from './tips/reducer'
import auth from './auth/reducer'
import confirmations from './confirmations/reducer'
import dashboard from './dashboard/reducer'
import nav from './nav/reducer'
import errors from './errors/reducer'
import success from './success/reducer'
import reports from './reports/reducer'
import announcements from './announcements/reducer'
import drivers from './drivers/reducer'
import vehicles from './vehicles/reducer'
import info from './info/reducer'

const rootReducer = combineReducers({
  dashboardControls,
  accounts,
  products,
  categories,
  departments,
  orders,
  networks,
  stores,
  discounts,
  coupons,
  news,
  tips,
  form: formReducer,
  auth,
  confirmations,
  dashboard,
  nav,
  errors,
  success,
  reports,
  announcements,
  drivers,
  vehicles,
  info
})

export default rootReducer
