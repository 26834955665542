import * as actions from './constants'

const initialState = {
  emailTemplateItemFormData: null
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.EMAIL_TEMPLATE_FETCHED:
      return {
        ...state,
        emailTemplateItemFormData: action.payload
      }
    default:
      return state
  }
}
