import Cookies from 'js-cookie'
import { LOGIN, LOGOUT, SET_AUTH_PROGRESS, SET_AUTH_ERROR } from './constants'

const AUTH_COOKIE_NAME = 'admin-token'

const initialAPIToken = Cookies.get(AUTH_COOKIE_NAME)

const initialState = {
  apiToken: initialAPIToken,
  isAuthenticated: Boolean(initialAPIToken),
  isAuthInProgress: false,
  authError: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      Cookies.set(AUTH_COOKIE_NAME, action.payload, { expires: 365 })
      return {
        ...state,
        isAuthenticated: Boolean(action.payload),
        apiToken: action.payload
      }
    case LOGOUT:
      Cookies.remove(AUTH_COOKIE_NAME)
      return {
        ...state,
        isAuthenticated: false
      }
    case SET_AUTH_PROGRESS:
      return {
        ...state,
        isAuthInProgress: action.payload
      }
    case SET_AUTH_ERROR:
      return {
        ...state,
        authError: action.payload
      }
    default:
      return state
  }
}

export default reducer
