import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable'

import Loader from './layout/Loader'
import Aux from '../hoc/_Aux'
import ScrollToTop from './layout/ScrollToTop'

import apiService from '../services/ApiService'

import SignIn from './components/sign-in/SignIn'
import Confirmation from './components/confirmations/Confirmations'
const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader
})

const App = props => {
  const checkAuth = () => {
    if (props.isAuthenticated) {
      apiService.setAuthHeader(props.apiToken)
      return (<AdminLayout />)
    } else {
      return (<Redirect to='/signin' />)
    }
  }

  return (
    <Aux>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path='/signin' exact={true} name='signin' component={SignIn} />
            <Route path='/' render={checkAuth} />
          </Switch>
        </Suspense>
      </ScrollToTop>
      <Confirmation />
    </Aux>
  )
}

export default connect(state => ({
  apiToken: state.auth.apiToken,
  isAuthenticated: state.auth.isAuthenticated
}))(App)
