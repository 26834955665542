import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'

import { removeConfirmation } from '../../../store/confirmations/action-creator'

const Confirmations = props => {
  const onConfirmationButtonClick = (confirmation, onClick) => {
    if (typeof onClick === 'function') {
      onClick(confirmation)
    }
    props.removeConfirmation(confirmation.id)
  }

  return (
    <Fragment>
      {props.confirmations.map(confirmation => (
        <Modal
          key={confirmation.id}
          show={confirmation.isShown}
          onHide={() => props.removeConfirmation(confirmation.id)} centered>
          <Modal.Header closeButton>
            <Modal.Title>{confirmation.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{confirmation.description}</Modal.Body>
          <Modal.Footer>
            {confirmation.buttons.map(button => (
              <Button
                key={button.text}
                variant={button.variant}
                onClick={() => onConfirmationButtonClick(confirmation, button.onClick)}>
                {button.text}
              </Button>
            ))}
          </Modal.Footer>
        </Modal>
      ))}
    </Fragment>
  )
}

export default connect(
  state => ({ ...state.confirmations }),
  { removeConfirmation }
)(Confirmations)
