import * as actions from './constants'

const initialState = {
  stores: [],
  allStores: [],
  storeItemFormData: null,
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  itemsPerPage: 20,
  catalog: {
    departments: [],
    categories: [],
    products: []
  }
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_STORES_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_STORES_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.SET_STORES_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.STORES_FETCHED:
      return {
        ...state,
        stores: action.payload.list,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.DELETE_STORE:
      return {
        ...state,
        stores: state.stores.filter(({ id }) => id !== action.payload)
      }
    case actions.NEW_STORE_CREATED:
    case actions.CLEAR_STORE_DATA:
      return {
        ...state,
        storeItemFormData: null
      }
    case actions.STORE_FETCHED: {
      const storeItemData = {
        id: action.payload.id,
        name: action.payload.name,
        network_id: action.payload.network.id,
        work_schedule: action.payload.work_schedule,
        address: action.payload.address,
        images: action.payload.images
      }
      return {
        ...state,
        storeItemFormData: storeItemData
      }
    }
    case actions.ALL_STORES_FETCHED:
      return {
        ...state,
        allStores: action.payload
      }
    case actions.STORE_UPDATED:
      return {
        ...state,
        storeItemFormData: {
          ...state.storeItemFormData,
          images: action.payload.images
        }
      }
    case actions.STORE_FETCH_CATALOG:
      return {
        ...state,
        catalog: action.payload
      }
    default:
      return state
  }
}
