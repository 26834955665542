export const STORES_FETCHED = 'STORES_FETCHED'
export const SET_STORES_PROGRESS = 'SET_STORES_PROGRESS'
export const ALL_STORES_FETCHED = 'ALL_STORES_FETCHED'
export const SET_STORES_PAGE = 'SET_STORES_PAGE'
export const SET_STORES_ITEMS_PER_PAGE = 'SET_STORES_ITEMS_PER_PAGE'
export const DELETE_STORE = 'DELETE_STORE'
export const NEW_STORE_CREATED = 'NEW_STORE_CREATED'
export const STORE_FETCHED = 'STORE_FETCHED'
export const CLEAR_STORE_DATA = 'CLEAR_STORE_DATA'
export const STORE_UPDATED = 'STORE_UPDATED'
export const STORE_FETCH_CATALOG = 'STORE_FETCH_CATALOG'
