export const ACCOUNTS_USERS_CUSTOMER_CREATED = 'ACCOUNTS_USERS_CUSTOMER_CREATED'
export const ACCOUNTS_USERS_FETCHED = 'ACCOUNTS_USERS_FETCHED'
export const ACCOUNTS_USERS_REMOVE = 'ACCOUNTS_USERS_REMOVE'
export const ACCOUNTS_SET_USER_FORM_DATA = 'ACCOUNTS_SET_USER_FORM_DATA'
export const ACCOUNTS_CUSTOMERS_SET_PAGE = 'ACCOUNTS_CUSTOMERS_SET_PAGE'
export const ACCOUNTS_CUSTOMERS_SET_ITEMS_PER_PAGE = 'ACCOUNTS_CUSTOMERS_SET_ITEMS_PER_PAGE'
export const ACCOUNTS_CUSTOMERS_LOADING = 'ACCOUNTS_CUSTOMERS_LOADING'
export const ACCOUNTS_CUSTOMERS_LOADING_STOP = 'ACCOUNTS_CUSTOMERS_LOADING_STOP'
export const ACCOUNTS_CUSTOMERS_FETCHED = 'ACCOUNTS_CUSTOMERS_FETCHED'
export const ACCOUNTS_CUSTOMERS_CUSTOMER_FETCHED = 'ACCOUNTS_CUSTOMERS_CUSTOMER_FETCHED'
export const ACCOUNTS_CUSTOMERS_CHANGE_STATUS = 'ACCOUNTS_CUSTOMERS_CHANGE_STATUS'
export const ACCOUNTS_CUSTOMERS_CLEAR_DATA = 'ACCOUNTS_CUSTOMERS_CLEAR_DATA'
export const ACCOUNTS_CUSTOMERS_ADD_ADDRESS = 'ACCOUNTS_CUSTOMERS_ADD_ADDRESS'
export const ACCOUNTS_CUSTOMERS_REMOVE_ADDRESS = 'ACCOUNTS_CUSTOMERS_REMOVE_ADDRESS'
export const ACCOUNTS_CUSTOMERS_EDIT_ADDRESS = 'ACCOUNTS_CUSTOMERS_EDIT_ADDRESS'
export const ACCOUNTS_CUSTOMERS_INIT_ADDRESS_DATA = 'ACCOUNTS_CUSTOMERS_INIT_ADDRESS_DATA'
export const ACCOUNTS_USERS_SET_PAGE = 'ACCOUNTS_USERS_SET_PAGE'
export const ACCOUNTS_USERS_SET_ITEMS_PER_PAGE = 'ACCOUNTS_USERS_SET_ITEMS_PER_PAGE'
export const ACCOUNTS_USERS_LOADING = 'ACCOUNTS_USERS_LOADING'
export const ACCOUNTS_USERS_LOADING_STOP = 'ACCOUNTS_USERS_LOADING_STOP'
export const ACCOUNTS_USERS_USER_FETCHED = 'ACCOUNTS_USERS_USER_FETCHED'
export const ACCOUNTS_USERS_CHANGE_STATUS = 'ACCOUNTS_USERS_CHANGE_STATUS'
export const ACCOUNTS_CUSTOMER_PASSWORD_CHANGE = 'ACCOUNTS_CUSTOMER_PASSWORD_CHANGE'
export const ACCOUNTS_CUSTOMER_BONUSES_FETCHED = 'ACCOUNTS_CUSTOMER_BONUSES_FETCHED'
export const ACCOUNTS_CUSTOMER_BONUS_ADDED = 'ACCOUNTS_CUSTOMER_BONUS_ADDED'
export const ACCOUNTS_CUSTOMER_CART_FETCHED = 'ACCOUNTS_CUSTOMER_CART_FETCHED'
