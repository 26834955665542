import * as vehiclesActions from './constants'

const initialState = {
  vehicleFormData: {},
  loading: false,
  list: []
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case vehiclesActions.VEHICLES_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case vehiclesActions.VEHICLES_FETCHED:
      return {
        ...state,
        list: action.payload.list,
        loading: false
      }
    case vehiclesActions.VEHICLE_FETCHED:
      return {
        ...state,
        vehicleFormData: action.payload
      }
    case vehiclesActions.VEHICLE_DELETE: {
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload)
      }
    }
    case vehiclesActions.VEHICLE_CLEAR_DATA:
      return {
        ...state,
        vehicleFormData: {}
      }
    default:
      return state
  }
}
