import * as actions from './constants'
import priceToString from '../../utils/price-to-string'
import parseDateString from '../../utils/parse-date-string'

const initialState = {
  coupons: [],
  couponFormData: null,
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  itemsPerPage: 20,
  availabilities: null,
  coupon: null
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_COUPONS_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_COUPONS_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.SET_COUPONS_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.COUPON_ADDED:
      return {
        ...state,
        coupon: action.payload
      }
    case actions.COUPONS_FETCHED:
      return {
        ...state,
        coupons: action.payload.list,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.DELETE_COUPON:
      return {
        ...state,
        coupons: state.coupons.filter(({ id }) => id !== action.payload)
      }
    case actions.CLEAR_COUPON_DATA:
      return {
        ...state,
        couponFormData: null,
        availabilities: null
      }
    case actions.COUPON_FETCHED: {
      const coupon = action.payload
      coupon.gift_products = coupon.gift_products.map(item => item.product)
      coupon.cash_discount = priceToString(coupon.cash_discount)
      coupon.minimal_order_total = priceToString(coupon.minimal_order_total)
      coupon.date_start = coupon.date_start
        ? parseDateString(coupon.date_start)
        : null
      coupon.date_end = coupon.date_end
        ? parseDateString(coupon.date_end)
        : null
      return {
        ...state,
        couponFormData: coupon,
        availabilities: coupon.availabilities
      }
    }
    case actions.COUPON_STORES_AVAILABILITY_FETCHED:
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          available_stores: action.payload
        }
      }
    case actions.COUPON_NETWORKS_AVAILABILITY_FETCHED:
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          available_store_networks: action.payload
        }
      }
    default:
      return state
  }
}
