import * as actionTypes from './constants'
import priceToString from '../../utils/price-to-string'
import shortid from 'shortid'

const initialState = {
  list: [],
  totalItems: 0,
  totalPages: 1,
  loading: false,
  currentPage: 1,
  orderingsPerPage: 20,
  orderingsPage: 1,
  productFormData: {
    enabled: true
  },
  productContainedFormData: {
    enabled: true
  },
  contained_products: [],
  productLocations: [],
  productLocationFormData: null,
  productStorePriceData: null,
  productNetworkPriceData: null,
  itemsPerPage: 20,
  orderings: {
    list: [],
    totalItems: 0,
    totalPages: 1
  },
  productPrices: {},
  newArrivals: {
    list: [],
    totalItems: 0,
    totalPages: 1
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CATALOG_PRODUCTS_PAGE_FETCHED:
      return {
        ...state,
        loading: false,
        list: action.payload.list.map(item => ({ ...item, gross_margin: (item.sale_price - item.store_price) / item.sale_price })),
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages,
        currentPage: action.payload.paging_header.current_page
      }
    case actionTypes.CATALOG_PRODUCTS_ARRIVALS_PAGE_FETCHED: {
      let validNewArrivals = {}
      if (action.payload.paging_header.total_items > 100) {
        validNewArrivals.list = []
        validNewArrivals.totalItems = 0
        validNewArrivals.totalPages = 0
        validNewArrivals.currentPage = 1
      } else {
        validNewArrivals = {
          list: action.payload.list.map(item => ({ ...item, gross_margin: (item.sale_price - item.store_price) / item.sale_price })),
          totalItems: action.payload.paging_header.total_items,
          totalPages: action.payload.paging_header.total_pages,
          currentPage: action.payload.paging_header.current_page
        }
      }

      return {
        ...state,
        loading: false,
        newArrivals: validNewArrivals
      }
    }
    case actionTypes.CATALOG_PRODUCTS_PRODUCT_CLEAR:
      return {
        ...state,
        productFormData: {
          enabled: true
        }
      }
    case actionTypes.CATALOG_PRODUCTS_CLEAR:
      return {
        ...state,
        list: []
      }
    case actionTypes.CATALOG_PRODUCTS_PRODUCT_FETCHED:
    case actionTypes.CATALOG_PRODUCTS_PRODUCT_CATEGORY:
    case actionTypes.CATALOG_PRODUCTS_PRODUCT_SAVED: {
      const product = action.payload
      product.sale_price = priceToString(product.sale_price)
      product.store_price = priceToString(product.store_price)
      product.department = product.department_ids[0]
      product.category = product.category_ids[0]
      product.gross_margin = ((product.sale_price - product.store_price) / product.sale_price).toFixed(2)
      if (product.size) {
        product.height = product.size.height
        product.width = product.size.width
        product.length = product.size.length
        product.volume = product.size.volume
        product.weight = product.size.weight
      } else {
        product.height = 0
        product.width = 0
        product.length = 0
        product.volume = 0
        product.weight = 0
      }
      product.locations = product.locations.map((loc) => {
        const preparedLoc = { ...loc }
        const location = product.prices.store_prices.find((location) => loc.store_id === location.store_id)
        if (location) {
          preparedLoc.price = priceToString(location.sale_price)
        }
        return preparedLoc
      })
      product.prices.store_prices = product.prices.store_prices.map((price) => {
        return { ...price, sale_price: priceToString(price.sale_price) }
      })
      product.prices.store_network_prices = product.prices.store_network_prices.map((price) => {
        return { ...price, sale_price: priceToString(price.sale_price) }
      })
      return {
        ...state,
        productFormData: product,
        productLocations: product.locations,
        productPrices: product.prices,
        contained_products: product.contained_products
      }
    }
    case actionTypes.CATALOG_PRODUCTS_SINGLE_PRODUCT_FETCHED: {
      const product = action.payload
      product.sale_price = priceToString(product.sale_price)
      product.store_price = priceToString(product.store_price)
      product.gross_margin = ((product.sale_price - product.store_price) / product.sale_price).toFixed(2)
      return {
        ...state,
        productFormData: product
      }
    }

    case actionTypes.CATALOG_PRODUCTS_PRODUCT_CREATED:
      return {
        ...state,
        list: [...state.list, action.payload]
      }
    case actionTypes.CATALOG_PRODUCTS_SET_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actionTypes.CATALOG_PRODUCTS_SET_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actionTypes.CATALOG_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.CATALOG_LOCATION_SET:
      return {
        ...state,
        productLocationFormData: action.payload
      }
    case actionTypes.CATALOG_PRODUCT_STORE_PRICE:
      return {
        ...state,
        productStorePriceData: action.payload
      }
    case actionTypes.CATALOG_PRODUCT_NETWORK_PRICE:
      return {
        ...state,
        productNetworkPriceData: action.payload
      }
    case actionTypes.CATALOG_PRODUCTS_LOADING_STOP:
      return {
        ...state,
        loading: false
      }
    case actionTypes.CATALOG_PRODUCTS_ORDERINGS_FETCHED:
      return {
        ...state,
        orderings: {
          list: action.payload.list,
          totalItems: action.payload.paging_header.total_items,
          totalPages: action.payload.paging_header.total_pages
        }
      }
    case actionTypes.SET_ORDERINGS_PAGE:
      return {
        ...state,
        orderingsPage: action.payload
      }
    case actionTypes.SET_ORDERINGS_ITEMS_PER_PAGE:
      return {
        ...state,
        orderingsPerPage: action.payload
      }
    case actionTypes.CATALOG_PRODUCTS_LOCATIONS_FETCHED:
      return {
        ...state,
        productLocations: action.payload.map(item => {
          item.id = shortid.generate()
          return item
        }),
        productLocationFormData: null
      }
    case actionTypes.CATALOG_PRODUCTS_PRICES_FETCHED:
      return {
        ...state,
        productPrices: {
          ...state.productPrices,
          store_prices: action.payload.map(item => {
            item.id = shortid.generate()
            return item
          })
        },
        productStorePriceData: null
      }
    case actionTypes.CATALOG_PRODUCTS_NETWORK_PRICES_FETCHED:
      return {
        ...state,
        productPrices: {
          ...state.productPrices,
          store_network_prices: action.payload.map(item => {
            item.id = shortid.generate()
            return item
          })
        },
        productStorePriceData: null
      }
    case actionTypes.CATALOG_CHANGE_PRODUCT_COUNT: {
      const { productId, count } = action.payload
      const { contained_products = [] } = state
      function counter (items) {
        return items.map(entry => {
          return (entry.product ? entry.product.id : entry.id) === productId
            ? { ...entry, count }
            : entry
        })
          .filter(({ count }) => count)
      }
      return {
        ...state,
        contained_products: counter(contained_products)
      }
    }
    case actionTypes.CATALOG_PRODUCT_ADD_PRODUCT: {
      const { contained_products = [] } = state
      const { product } = action.payload
      return {
        ...state,
        contained_products: [...contained_products, { count: 1, product }]
      }
    }
    case actionTypes.CATALOG_CLEAR_CONTAINED_PRODUCTS: {
      return {
        ...state,
        contained_products: []
      }
    }
    default:
      return state
  }
}

export default reducer
