import apiService from './ApiService'

class UsersService {
  async login ({ email, password }) {
    const { api_token: apiToken } = await apiService.post('/users/login', {
      email,
      password
    })
    return apiToken
  }
}

export const usersService = new UsersService()
