export const DISCOUNTS_FETCHED = 'DISCOUNTS_FETCHED'
export const SET_DISCOUNTS_PROGRESS = 'SET_DISCOUNTS_PROGRESS'
export const SET_DISCOUNT_PAGE = 'SET_DISCOUNT_PAGE'
export const SET_DISCOUNT_ITEMS_PER_PAGE = 'SET_DISCOUNT_ITEMS_PER_PAGE'
export const DELETE_DISCOUNT = 'DELETE_DISCOUNT'
export const DISCOUNT_FETCHED = 'DISCOUNT_FETCHED'
export const DISCOUNT_UPDATED = 'DISCOUNT_UPDATED'
export const DISCOUNT_IMAGE_ADDED = 'DISCOUNT_IMAGE_ADDED'
export const DISCOUNT_IMAGE_REMOVED = 'DISCOUNT_IMAGE_REMOVED'
export const DISCOUNT_DATA_CLEAR = 'DISCOUNT_DATA_CLEAR'
export const DISCOUNT_STORES_AVAILABILITY_FETCHED = 'DISCOUNT_STORES_AVAILABILITY_FETCHED'
export const DISCOUNT_NETWORKS_AVAILABILITY_FETCHED = 'DISCOUNT_NETWORKS_AVAILABILITY_FETCHED'
