import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import './../../../assets/scss/style.scss'
import Aux from '../../../hoc/_Aux'
import Breadcrumb from '../../layout/AdminLayout/Breadcrumb'
import { doLogin } from '../../../store/auth/action-creator'

const SignIn = props => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  })

  if (props.isAuthenticated) {
    return (<Redirect to="/" />)
  }

  const doLogin = event => {
    event.preventDefault()
    props.doLogin(credentials)
  }
  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <form className="card" onSubmit={doLogin}>
            <div className="card-body text-center">
              <div className="mb-4">
                <i className="feather icon-unlock auth-icon" />
              </div>
              <h3 className="mb-4">Login</h3>
              {props.authError !== null &&
                <div className="alert alert-danger" role="alert">
                  {props.authError}
                </div>
              }
              <div className="input-group mb-3">
                <input
                  type="input" className="form-control" placeholder="Email" required
                  onChange={e => setCredentials({ ...credentials, email: e.target.value })}
                />
              </div>
              <div className="input-group mb-4">
                <input
                  type="password" className="form-control" placeholder="password" required
                  onChange={e => setCredentials({ ...credentials, password: e.target.value })}
                />
              </div>
              <div className="container">
                <div className="row justify-content-md-center">
                  <button className="btn btn-primary shadow-2 btn-block col-4" disabled={props.isAuthInProgress}>
                    {props.isAuthInProgress
                      ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <span>Login</span>
                    }
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Aux>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAuthInProgress: state.auth.isAuthInProgress,
  authError: state.auth.authError
})

export default connect(
  mapStateToProps,
  { doLogin }
)(SignIn)
