import * as actions from './constants'

const initialState = {
  categories: [],
  currentDepartmentId: null,
  categoriesItemFormData: null,
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  itemsPerPage: 20
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_CATEGORIES_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_CATEGORIES_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.SET_CATEGORIES_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.CATEGORIES_FETCHED:
      return {
        ...state,
        categories: action.payload.list,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(({ id }) => id !== action.payload)
      }
    case actions.SET_CATEGORY_DEPARTMENT_ID:
      return {
        ...state,
        currentDepartmentId: action.payload
      }
    case actions.CLEAR_CATEGORIES_ITEM_DATA:
      return {
        ...state,
        categoriesItemFormData: null
      }
    case actions.CATEGORIES_ITEM_FETCHED:
      return {
        ...state,
        categoriesItemFormData: action.payload
      }
    default:
      return state
  }
}
