import * as actions from './constants'

const initialState = {
  metrics: {},
  customers: {
    loading: false,
    list: [],
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 20
  }
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.REPORTS_CUSTOMERS_METRICS_SET_PAGE:
      return {
        ...state,
        customers: {
          ...state.customers,
          currentPage: action.payload
        }
      }
    case actions.REPORTS_CUSTOMERS_METRICS_SET_ITEMS_PER_PAGE:
      return {
        ...state,
        customers: {
          ...state.customers,
          itemsPerPage: action.payload
        }
      }
    case actions.REPORTS_CUSTOMERS_METRICS_LIST_FETCHED:
      return {
        ...state,
        customers: {
          loading: false,
          list: action.payload.list,
          totalItems: action.payload.paging_header.total_items,
          totalPages: action.payload.paging_header.total_pages,
          currentPage: state.customers.currentPage
        }
      }
    case actions.REPORTS_CUSTOMER_METRICS_LOADING:
      return {
        customers: {
          ...state.customers,
          loading: true
        }
      }
    case actions.REPORTS_GLOBAL_METRICS_FETCHED:
      return {
        ...state,
        metrics: action.payload
      }
    case actions.REPORTS_CLEAR_METRIC_DATA:
      return {
        ...state,
        metrics: {}
      }
    default:
      return state
  }
}
