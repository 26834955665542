import { SET_ERROR, DELETE_ERROR, SET_ERROR_WITH_ACTIONS } from './constants'

const initialState = {
  error: ''
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        additionalActions: null
      }
    case DELETE_ERROR:
      return {
        ...state,
        error: '',
        additionalActions: null
      }
    case SET_ERROR_WITH_ACTIONS:
      return {
        ...state,
        error: action.payload.error,
        additionalActions: action.payload.additionalActions
      }
    default:
      return state
  }
}
