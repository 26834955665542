export const DEPARTMENTS_FETCHED = 'DEPARTMENTS_FETCHED'
export const SET_DEPARTMENTS_PROGRESS = 'SET_DEPARTMENTS_PROGRESS'
export const SET_DEPARTMENTS_PAGE = 'SET_DEPARTMENTS_PAGE'
export const SET_DEPARTMENTS_ITEMS_PER_PAGE = 'SET_DEPARTMENTS_ITEMS_PER_PAGE'
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'
export const CLEAR_DEPARTMENTS_ITEM_DATA = 'CLEAR_DEPARTMENT_ITEM_DATA'
export const DEPARTMENTS_ITEM_FETCHED = 'DEPARTMENTS_ITEM_FETCHED'
export const DEPARTMENTS_ITEM_EDITED = 'DEPARTMENTS_ITEM_EDITED'
export const DEPARTMENTS_ITEM_ADDED = 'DEPARTMENTS_ITEM_ADDED'
export const DEPARTMENTS_ITEM_IMAGE_ADDED = 'DEPARTMENTS_ITEM_IMAGE_ADDED'
export const DEPARTMENTS_ITEM_IMAGE_REMOVED = 'DEPARTMENTS_ITEM_IMAGE_REMOVED'
