import * as driversActions from './constants'

const initialState = {
  driverFormData: {},
  loading: false,
  list: [],
  currentPage: 1,
  totalPages: 1,
  deliveryProfile: {},
  bringgDrivers: []
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case driversActions.DRIVERS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case driversActions.DRIVERS_FETCHED:
      return {
        ...state,
        list: action.payload.list,
        totalPages: action.payload.paging_header.total_pages,
        totalItems: action.payload.paging_header.total_items,
        currentPage: action.payload.paging_header.current_page,
        loading: false
      }
    case driversActions.DRIVER_FETCHED:
      return {
        ...state,
        driverFormData: action.payload
      }
    case driversActions.DRIVER_CLEAR_DATA:
      return {
        ...state,
        driverFormData: {}
      }
    case driversActions.DELIVERY_PROFILE_FETCHED:
      return {
        ...state,
        loading: false,
        deliveryProfile: {
          ...action.payload
        }
      }
    case driversActions.BRINGG_DRIVERS_FETCHED:
      return {
        ...state,
        bringgDrivers: action.payload
      }
    default:
      return state
  }
}
