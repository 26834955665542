import * as actions from './constants'

const initialState = {
  announcements: [],
  announcementsItemFormData: null,
  isInProgress: false,
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  itemsPerPage: 20
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.SET_ANNOUNCEMENTS_PROGRESS:
      return {
        ...state,
        isInProgress: action.payload
      }
    case actions.SET_ANNOUNCEMENTS_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.SET_ANNOUNCEMENTS_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      }
    case actions.ANNOUNCEMENTS_FETCHED:
      return {
        ...state,
        announcements: action.payload.list,
        totalItems: action.payload.paging_header.total_items,
        totalPages: action.payload.paging_header.total_pages
      }
    case actions.DELETE_ANNOUNCEMENT:
      return {
        ...state,
        announcements: state.announcements.filter(({ id }) => id !== action.payload)
      }
    case actions.CLEAR_ANNOUNCEMENTS_ITEM_DATA:
      return {
        ...state,
        announcementsItemFormData: null
      }
    case actions.ANNOUNCEMENTS_ITEM_FETCHED:
    case actions.ANNOUNCEMENTS_ITEM_EDITED:
    case actions.ANNOUNCEMENTS_ITEM_ADDED: {
      const announcementsItem = action.payload
      if (announcementsItem.header) {
        announcementsItem.headerText = announcementsItem.header.text
        announcementsItem.alignHeader = announcementsItem.header.align
        announcementsItem.justifyHeader = announcementsItem.header.justify
        announcementsItem.colorHeader = announcementsItem.header.color?.toString(16)
      }
      if (announcementsItem.description) {
        announcementsItem.descriptionText = announcementsItem.description.text
        announcementsItem.alignDescription = announcementsItem.description.align
        announcementsItem.justifyDescription = announcementsItem.description.justify
        announcementsItem.colorDescription = announcementsItem.description.color?.toString(16)
      }
      if (announcementsItem.additional_text) {
        announcementsItem.additionalText = announcementsItem.additional_text.text
        announcementsItem.alignAdditionalText = announcementsItem.additional_text.align
        announcementsItem.justifyAdditionalText = announcementsItem.additional_text.justify
        announcementsItem.colorAdditionalText = announcementsItem.additional_text.color?.toString(16)
      }
      announcementsItem.background_color = announcementsItem.background_color?.toString(16)
      return {
        ...state,
        announcementsItemFormData: announcementsItem
      }
    }
    default:
      return state
  }
}
