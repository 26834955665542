import * as actions from './constants'

const initialState = {}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.DASHBOARD_GET_OVERVIEW:
      return { ...state, ...action.payload }
    case actions.DASHBOARD_GET_CHART_DATA:
      return { ...state, charts: action.payload }
    case actions.DASHBOARD_GET_TOP_BUYERS:
      return { ...state, topBuyers: action.payload }
    case actions.DASHBOARD_GET_TOP_COUPONS:
      return { ...state, topCoupons: action.payload }
    case actions.DASHBOARD_GET_TOP_PRODUCTS:
      return { ...state, topProducts: action.payload }
    default:
      return state
  }
}
