import * as actions from './constants'

const initialState = {
  confirmations: []
}

export default function reduce (state = initialState, action) {
  switch (action.type) {
    case actions.ADD_CONFIRMATION:
      return {
        ...state,
        confirmations: [
          ...state.confirmations,
          action.payload
        ]
      }
    case actions.REMOVE_CONFIRMATION:
      return {
        ...state,
        confirmations: state.confirmations.filter(({ id }) => id !== action.payload)
      }
    default:
      return state
  }
}
