function priceToString (price) {
  if (price === null || price === undefined) {
    return ''
  }

  const strPrice = String(price)
  const dollars = strPrice.slice(0, -2) || '0'
  const cents = strPrice.slice(-2)
  return dollars + '.' + cents
}

export default priceToString
