import { store } from '../store/index'
import { doLogout } from '../store/auth/action-creator'
import { showError } from '../store/errors/action-creator'

class HttpErrorService {
  handleResponse (e) {
    if (e.response && e.response.status === 401) {
      console.error('401 response received. Logout from HttpErrorService.', e)
      store.dispatch(doLogout())
      window.location.reload()
    } else {
      console.error(e)
      store.dispatch(showError(e))
    }
  }
}

export default new HttpErrorService()
